import { defineComponent } from '@vue/composition-api';
import type {} from '@vue/composition-api';

import PageNodeMain from '@/components/PageNodeMain';
import PageNodeSub from '@/components/PageNodeSub/index.vue';

import PhotoAlbum from '@/components/Article/PhotoAlbum';

export default defineComponent({
    components: {
        PageNodeMain,
        PageNodeSub,
        PhotoAlbum,
    },
    setup() {
        return {};
    },
});
